.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 500px;
  margin: auto;
}

.edit-form {
  max-width: 500px;
  margin: auto;
}

button.button {
  margin: 0 0 0 10px;
}

.panel-block {
  border-left: 5px solid #50c878;
}
.rows {
  display: flex;

  color: black;
  align-items: center;
  justify-content: flex-start;
}
.columnname {
  flex: 4;

  font-size: 16px;
  min-width: 9rem;
  max-width: 9rem;
}
.Columns {
  flex: 4;
  min-width: 9rem;
  max-width: 9rem;
  height: auto;
  font-size: 16px;
}
