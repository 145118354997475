@charset "utf-8"

@import '../../node_modules/bulma/sass/utilities/_all.sass'

@import '../../node_modules/bulma/sass/base/generic.sass'
@import '../../node_modules/bulma/sass/base/animations.sass'
@import '../../node_modules/bulma/sass/elements/_all.sass'
@import '../../node_modules/bulma/sass/elements/button.sass'
@import '../../node_modules/bulma/sass/elements/container.sass'
@import '../../node_modules/bulma/sass/elements/title.sass'
@import '../../node_modules/bulma/sass/components/_all.sass'
@import '../../node_modules/bulma/sass/components/navbar.sass'
@import '../../node_modules/bulma/sass/grid/_all.sass'
@import '../../node_modules/bulma/sass/grid/columns.sass'
@import '../../node_modules/bulma/sass/form/_all.sass'
@import '../../node_modules/bulma/sass/components/panel.sass'
@import '../../node_modules/bulma/sass/elements/tag.sass'
@import '../../node_modules/bulma/sass/helpers/_all.sass'
@import '../../node_modules/bulma/sass/layout/_all.sass'
@import '../../node_modules/bulma/sass/helpers/spacing.sass'
@import '../../node_modules/bulma/sass/helpers/typography.sass'
@import '../../node_modules/bulma-extensions/bulma-tagsinput/dist/css/bulma-tagsinput.sass'

@import '../../node_modules/bulma/sass/components/card.sass'
$tooltip-arrow-size: 0px
@import '../../node_modules/bulma-tooltip/src/sass'
@import '../../node_modules/bulma/sass/base/minireset.sass'

html
    height: 100vh
    width: 100vw
    display: flex
    padding: 0
    margin: 0
    background: rgb(184, 238, 105)
    background: linear-gradient(90deg, rgba(184,238,105,1) 0%, rgba(0,103,88,1) 100%)

body
    margin: 0
    height: 100%
    width: 100%
    padding-top: 70px !important
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

code
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

ul 
    list-style-type: disc !important


.no-bulletpoint
    list-style-type: none
    padding-left: 0
    margin-left: 0

.no-border
    background: none
    border: none
    padding: 0

.is-disabled
    pointer-events: none
    opacity: 0.7

.fullscreenmodal
    padding: 0 !important
    margin: 0 !important
    width: 100vw
    height: 100vh

.modal-dialog
    width: 100vw
    max-width: none
    height: 100vh
    margin: 0

.modal-content
    border: 0
    border-radius: 0

.modal-card
    width: 95vw
    height: 95vh

.modal-body
    overflow-y: auto

.modal-background
    background: rgb(184, 238, 105)
    background: linear-gradient(90deg, rgba(184,238,105,1) 0%, rgba(0,103,88,1) 100%)

.table-container
    overflow: visible
